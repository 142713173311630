document.addEventListener("DOMContentLoaded", () => {

  // open dropdown if li active
  if (document.querySelector(".nav-aside")) {
    const activeLiElements = document.querySelectorAll('.nav-aside .main-level > li.active ul ul > li.active');
    activeLiElements.forEach(el => {
      el.parentElement.classList.add('show');
      el.parentElement.parentElement.classList.add('open');
    })
  }

  // dropdown for nav aside
  const navDropdown = document.querySelectorAll(".nav-dropdown");
  if (navDropdown) {
    navDropdown.forEach(el => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        el.nextElementSibling.classList.toggle('show')
        el.classList.toggle('open')
      })
    })
  }

  // in page nav
  setOverflowInPageNav()

  window.addEventListener("resize", function () {
    setTimeout(function () {
      setOverflowInPageNav()
    }, 800)
  });

})

function setOverflowInPageNav() {
  const inPageNavContainer = document.querySelector(".in-page-sub-nav");
  const inPageNavElement = document.querySelectorAll(".in-page-sub-nav ul li")
  const inPageDropdown = document.querySelector('.in-page-sub-nav-container .dropdown-menu')

  const widthOfInPageNavContainer = 0
  const widthOfInPageNavElements = 0

  const elementsNotInView = []

  if (inPageNavContainer) {
    widthOfInPageNavContainer = inPageNavContainer.clientWidth;
    document.querySelector('.inPageElementLength').value = inPageNavElement.length;

    inPageNavElement.forEach(item => {
      const itemPos = window.singleapi.getPosition(item, inPageNavContainer);
      if ((itemPos.x + item.clientWidth) > (widthOfInPageNavContainer - 70)) {
        elementsNotInView.push(item)
      }
      widthOfInPageNavElements += item.clientWidth;
    })

    inPageDropdown.innerHTML = ""
    elementsNotInView.forEach(el => {
      const newEl = el.cloneNode(true)
      newEl.classList.remove('nav-item')
      newEl.classList.add('dropdown-item')
      inPageDropdown.appendChild(newEl)
    })

    if (widthOfInPageNavElements > widthOfInPageNavContainer) {
      document.querySelector('.in-page-sub-nav ul.nav').classList.add('show')
      document.querySelector('.overflown-toggle').classList.add('show')
    } else {
      document.querySelector('.in-page-sub-nav ul.nav').classList.remove('show')
      document.querySelector('.overflown-toggle').classList.remove('show')
    }
  }
}

