document.addEventListener("DOMContentLoaded", () => {
  window.acquiring.getPosition = function (el, rel) {
    var x = 0, y = 0;
    do {
      x += el.offsetLeft;
      y += el.offsetTop;
      el = el.offsetParent;
    }
    while (el != rel)
    return { x: x, y: y };
  }

  window.acquiring.selectText = function(element) {
    var doc = document
        , text = element
        , range, selection
    ;    
    if (doc.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(text);
        range.select();
    } else if (window.getSelection) {
        selection = window.getSelection();        
        range = document.createRange();
        range.selectNodeContents(text);
        selection.removeAllRanges();
        selection.addRange(range);
    }
}

  // Wrap an HTMLElement around another HTMLElement or an array of them.
  HTMLElement.prototype.wrapAll = function (elms) {
    var el = elms.length ? elms[0] : elms;

    // Cache the current parent and sibling of the first element.
    var parent = el.parentNode;
    var sibling = el.nextSibling;

    // Wrap the first element (is automatically removed from its
    // current parent).
    this.appendChild(el);

    // Wrap all other elements (if applicable). Each element is
    // automatically removed from its current parent and from the elms
    // array.
    while (elms.length) {
      this.appendChild(elms[0]);
    }

    // If the first element had a sibling, insert the wrapper before the
    // sibling to maintain the HTML structure; otherwise, just append it
    // to the parent.
    if (sibling) {
      parent.insertBefore(this, sibling);
    } else {
      parent.appendChild(this);
    }
  };
})

