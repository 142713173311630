document.addEventListener("DOMContentLoaded", () => {
  const darkModeToggle = document.querySelector("#toggleMode");
  const prefersDarkMode = window.matchMedia("(prefers-color-scheme:dark)").matches;
  const currentTheme = localStorage.getItem("theme");


  if (currentTheme === "dark" || (currentTheme === null && prefersDarkMode)) {
    document.querySelector("#toggleMode").checked = true;
    setDark();
  } else {
    document.querySelector("#toggleMode").checked = false;
    setLight();
  }

  darkModeToggle.addEventListener("change", function () {
    let currentTheme = localStorage.getItem("theme");
    currentTheme === "light" ? setDark() : setLight()
  });

  function setDark() {
    document.documentElement.classList.add("dark-theme");
    document.documentElement.classList.remove("light-theme");
    document.querySelector("#toggleMode").checked = true;
    document.documentElement.style.display = "block";
    localStorage.setItem("theme", "dark");
  }
  function setLight() {
    document.documentElement.classList.add("light-theme");
    document.documentElement.classList.remove("dark-theme");
    document.querySelector("#toggleMode").checked = false;
    document.documentElement.style.display = "block";
    localStorage.setItem("theme", "light");
  }
})