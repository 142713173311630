document.addEventListener("DOMContentLoaded", () => {
  window.scrollToElement = function (id) {
    const element = document.getElementById(`${id}`);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - 100;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  }
})