document.addEventListener("DOMContentLoaded", () => {

  const backToTopLink = document.querySelector(".scroll-to-top")

  window.singleapi.showToTop = function () {
    if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 300) {
      backToTopLink.classList.add('show')
    } else {
      backToTopLink.classList.remove('show')
    }
  }

  backToTopLink.addEventListener('click', () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  })


  window.onscroll = () => {
    if (document.querySelector(".in-page-sub-nav")) {
      let scrollPosInPageNav = window.singleapi.getPosition(document.querySelector(".in-page-sub-nav"))
      if (scrollPosInPageNav.y === 0) {
        document.querySelector(".in-page-sub-nav").classList.add('remove-radius');
      } else {
        document.querySelector(".in-page-sub-nav").classList.remove('remove-radius');
      }
    }

    window.singleapi.showToTop();
  };
})

