document.addEventListener("DOMContentLoaded", () => {
  var allTablesOnPage = document.querySelectorAll("table:not(.payment-products)");

  if (allTablesOnPage.length > 0) {
    allTablesOnPage.forEach(t => {
      t.classList.add("table");
      const trs = document.querySelectorAll(`tr`);
      const tds = document.querySelectorAll(`td`);
      trs.forEach(row => {
        row.removeAttribute("style")
      })
      tds.forEach(cell => {
        cell.removeAttribute("style")
      })

      wrap(t, document.createElement('div'));
    })
  }
})

function wrap(el, wrapper) {
  wrapper.classList.add("table-responsive")
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
}