import hljs from 'highlight.js/lib/common';

document.addEventListener("DOMContentLoaded", () => {

  const codeNotFormatted = document.querySelectorAll('pre');

  codeNotFormatted.forEach(c => {
    const container = document.createElement('div');
    container.wrapAll(c);

    if (container.querySelector('code')) {
      container.classList.add('snippet-container');
      container.insertAdjacentHTML('afterbegin', '<a class="copy-to-clipboard-button btn btn-copy">Copy</a>');
      container.insertAdjacentHTML('beforeend', '<textarea style="display:none;"></textarea>');
      container.querySelector('pre').classList.add('code-snippet');

      const codeBlocks = document.querySelectorAll('pre code')
      codeBlocks.forEach(block => {
        hljs.highlightElement(block);
      })  

      const textA = container.querySelector('textarea');
      const btn = container.querySelector('.copy-to-clipboard-button');

      textA.value = unescapeHTML(container.querySelector('code').innerHTML.replace(/<br\s*\\?>/g, "\r\n").replace(/<\/?[^>]+(>|$)/g, ""));

      btn.addEventListener("click", function () {
        window.acquiring.selectText(container.querySelector('.code-snippet code'));
        navigator.clipboard.writeText(textA.value).then(() => {
          btn.setAttribute("data-copy-state", "copy-success");
          setTimeout(() => {
            document.getSelection().removeAllRanges()
            btn.removeAttribute("data-copy-state");
          }, 2000);
        });
      });
    }
  });
})

function unescapeHTML(v) {
  return v.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
}