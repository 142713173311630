document.addEventListener("DOMContentLoaded", () => {
  const copyBtns = document.querySelectorAll('.copy-button')

  copyBtns.forEach(btn => {
    btn.addEventListener('click', () => {
      const code = `#code-${btn.getAttribute('data-copyid')}`
      const textA = document.querySelector(`#text-${btn.getAttribute('data-copyid')}`)
      textA.value = document.querySelector(code).innerHTML.replace(/<\/?[^>]+(>|$)/g, "");
      window.singleapi.selectText(document.querySelector(code));
      navigator.clipboard.writeText(textA.value).then(() => {
        btn.innerHTML = 'Copied'
        btn.classList.add('copied')
        setTimeout(() => {
          textA.value = null
          btn.classList.remove('copied')
          btn.innerHTML = 'Copy code'
          window.getSelection().removeAllRanges()
        }, 2000)
      })
    })
  })
})