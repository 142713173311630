window.singleapi = {}

import './modules/utils'
import './modules/scroll'
import './modules/anchor'
import './modules/navigation'
import './modules/table'
import './modules/popover'
import './modules/code'
import './modules/cards'
